<template>
  <v-card class="mx-auto" height="100%" width="100%">
    <v-navigation-drawer color="white" app permanent >
      <v-subheader v-if="mini" style="font-size:12px;color:#ccc" ><img src="@/assets/logo.png" alt="" height="24px" style="border-radius:3px"></v-subheader>
      <v-subheader v-else  class="logo" style="height:136px;"><img src="@/assets/logo.png" alt="" height="64px" style="border-radius:3px ;margin-top:10px;margin-bottom:10px" ><span style="color:#000000DE;">腾泡报价小店 · 商家中心</span></v-subheader>
      <v-list flat>
        <v-list-item-group v-model="model" mandatory>
          <!-- 首页 -->
          <v-list-item link @click="goTo('/message')" :class="this.$route.path == '/message' ? 'orange lighten-5 orange--text' : ''">
            <v-list-item-icon style="margin-right: 15px;">
              <v-icon :class="this.$route.path == '/message' ? 'orange--text' : ''">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="this.$route.path == '/message' ? 'orange--text' : ''">首页</v-list-item-title>
          </v-list-item>
          <!-- 子用户管理 -->
          <v-list-item v-if="iss==0" link @click="goTo('/subuser')" :class="this.$route.path == '/subuser' ? 'orange lighten-5 orange--text' : ''">
            <v-list-item-icon  style="margin-right: 15px;">
              <v-icon :class="this.$route.path == '/subuser' ? 'orange--text' : ''">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="this.$route.path == '/subuser' ? 'orange--text' : ''">子用户管理</v-list-item-title>
          </v-list-item>
          <!-- 商品分类 -->
           <v-list-item link @click="goTo('/goodsclassify')" :class="this.$route.path == '/goodsclassify' ? 'orange lighten-5 orange--text' : ''">
            <v-list-item-icon  style="margin-right: 15px;">
              <v-icon :class="this.$route.path == '/goodsclassify' ? 'orange--text' : ''">mdi-format-list-bulleted-square</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="this.$route.path == '/goodsclassify' ? 'orange--text' : ''">商品分类</v-list-item-title>
          </v-list-item>
          <!-- 商品 -->
          <v-list-item link @click="goTo('/goods')" :class="this.$route.path =='/goods'||this.$route.path =='/goods/addgoods'||this.$route.path =='/goods/editgoods' ? 'orange lighten-5 orange--text' : ''">
            <v-list-item-icon  style="margin-right: 15px;">
              <v-icon  :class="this.$route.path =='/goods'||this.$route.path =='/goods/addgoods'||this.$route.path =='/goods/editgoods' ? 'orange--text' : ''">mdi-gift</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="this.$route.path =='/goods'||this.$route.path =='/goods/addgoods'||this.$route.path =='/goods/editgoods' ? 'orange--text' : ''">商品</v-list-item-title>
          </v-list-item>
          <!-- 报价单 -->
          <v-list-item link @click="goTo('/quotation')" :class="this.$route.path == '/quotation' ? 'orange lighten-5 orange--text' : ''">
            <v-list-item-icon  style="margin-right: 15px;">
              <v-icon  :class="this.$route.path == '/quotation' ? 'orange--text' : ''">mdi-receipt</v-icon>
            </v-list-item-icon>
            <v-list-item-title  :class="this.$route.path == '/quotation' ? 'orange--text' : ''">报价小店</v-list-item-title>
          </v-list-item>
        </v-list-item-group>

      </v-list>

      <template v-slot:append v-if="!mini">
         <div>
            <v-list-item class="center">
              <span class="center-text">&copy;{{new Date().getFullYear()}}</span><a href="https://www.tp-x.cn/" style="text-decoration:none;font-size:12px;color:#000"  target="_blank">&nbsp;腾泡科技</a>
            </v-list-item>
          </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import {loginOut} from '@/api/user.js'
import {mapState} from 'vuex'
export default {
  name: 'Sidebar',
  props:['mini'],
  data() {
    return {
      color: 'indigo',
      // title:'腾泡报价小店 · 商家中心',
      model:1,
    }
  },
 watch:{
   mini(val){
    //  console.log(val);
   },
 },
  computed:{
    ...mapState({
      iss:state => state.user.iss,
    })
  },
  mounted() {
    try {
        this.$nextTick(()=>{
          this.$router.push('/message');
        })
      } catch (err) {
        console.log(err)
      }
  },
  methods: {
    logOut() {
       loginOut(window.sessionStorage.getItem('token')).then(res=>{
        //  console.log(res);
         window.sessionStorage.clear();  
         this.$router.push('/login');
       })
    },
    goTo(path) {
        this.$router.push(path)
    },
    
  }
}
</script>

<style>
.active {
  background-color: yellow;
}
.center{
  display: flex;
  justify-content: center;
}
.center-text{
  font-size: 12px;
  display: flex;
  justify-content: center;
  padding:0;
}
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size:16px;
  font-weight: 500;
  width:256px;
}

</style>
