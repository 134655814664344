<template>
  <div>
    <v-navigation-drawer app permanent :mini-variant="mini">
      <side-bar :mini="mini" />
    </v-navigation-drawer>
    <v-app-bar app dense white class="mx-auto" style="box-shadow:none;" color="#fff">
      <v-btn fab small class="mr-4" color="grey darken-3" style="box-shadow:none;" @click="mini = !mini" text>
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <!-- <v-toolbar-title class="orange--text text--darken-3"><div style="font-size:16px !important">商家中心</div></v-toolbar-title> -->
      <v-spacer />
      
      <v-row style="height:100%" class="mr-6">
        <avatars />
      </v-row>
    </v-app-bar>
    <v-main style="background-color: #f2f5f8;">
        <v-container fluid style="min-height:94vh;min-width:1000px;">
             <router-view></router-view>
        </v-container>
    </v-main>
    <v-footer fixed />
  </div>
</template>

<script>
import SideBar from '@/components/SideBar'
import Avatars from '@/components/Avatars.vue'
import {mapActions,mapState} from 'vuex'
export default {
  components: { SideBar, Avatars },
  data() {
    return {
      mini: false,
     
    }
  },
  computed:{
    ...mapState({
      created: state => state.user.created,
      iss:state => state.user.iss,
      sub:state => state.user.sub,
    })
  },
  created(){
    this.loadData();
  },
  mounted(){
    this.listenPage();
  },
  methods:{
       ...mapActions(['getUserInfo']),
    listenPage(){
      window.onbeforeunload = (e)=>{
      // console.log(111);
        e = e || window.event;
        if (e) {
          this.getUserInfo().then(res=>{

          })
        }
      }
    },
    loadData(){
      if(!this.created){
        const newCreated = window.sessionStorage.getItem('created');
        this.$store.commit('setCreated', newCreated);
         window.sessionStorage.removeItem('created')
      }
      if(!this.iss){
        const newIss = window.sessionStorage.getItem('iss');
        this.$store.commit('setIss', newIss);
        window.sessionStorage.removeItem('iss')
      }
      if(!this.sub){
        const newSub=window.sessionStorage.getItem('sub');
        this.$store.commit('setSub', newSub);
        window.sessionStorage.removeItem('sub')
      }
    }
  }
}
</script>

<style scoped>

</style>
