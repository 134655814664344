<template>
  <v-container>
    <v-row justify="end" align="center">
      <v-menu bottom min-width="200px"  rounded offset-y >
        <template v-slot:activator="{ on }">
          <v-btn style="margin-top:6px;" color="eeeeee" depressed rounded class="header" middle v-on="on">
             <v-avatar
             size="28"
             >
              <img
                src="@/assets/default-header.png"
                alt="John"
              >
           </v-avatar>
            <strong v-html="sub" style="margin-left:10px"></strong>
            <v-icon>
              mdi-chevron-down 
            </v-icon>
          </v-btn>
        </template>
        <v-card flat>
          <v-list-item-content class="justify-center" >
            <div class="mx-auto text-center">
              <v-btn depressed rounded  @click="account" text>
                修改密码
              </v-btn>
              <v-divider class="my-3" />
              <v-btn class="red--text text--accent-4" depressed rounded text @click="Logout">
                退出
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-row>
    <v-row class="mb-2 pt-4 justify-end" style="width:86%">
       <v-dialog
        v-model="changeDialog"
        persistent
        max-width="600px"
        >
        <v-card>
        <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
            {{message}}
        </v-alert>
        <v-card-title>
        <span class="text-h5">修改密码</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            >
              <v-row justify="center" align-content="center">
                <v-col
                  cols="12"
                  sm="6"
                  md="9"
                  >
                  <v-text-field v-model="mobile" class="mx-auto col-12" label="手机号" :rules="[mobileRule]" require />
                </v-col>
                  <v-col
                  cols="12"
                  sm="6"
                  md="5"
                  >
                  <v-text-field
                      v-model="validates"
                      class="my-4 mx-auto col-12 "
                      :type="'text'"
                      label="输入验证码"
                      :rules="[v => !!v || '']"
                      require
                      oninput="value=value.replace(/[^\d]/g,'')"
                  />
                  </v-col>
                  <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  >
                  <v-btn
                  class="ma-2"
                  outlined
                  color="primary"
                  v-if="codeShow"
                  @click="getDynamic"
                  >
                  {{dynamic}}
                  </v-btn>
                  <v-btn
                  class="ma-2"
                  outlined
                  color="primary"
                  v-else
                  @click="getDynamic"
                  >
                  {{count}}秒后重试
                  </v-btn>
                  </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        color="grey darken-1"
        text
        @click="changeDialog = false;reset()"
        >
        取消
        </v-btn>
        <v-btn
        color="orange darken-1"
        text
        @click="validateMoblie"
        >
        下一步
        </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
     <v-row class="mb-2 pt-4 justify-end" style="width:86%">
      <v-dialog
          v-model="confirmDialog"
          persistent
          max-width="600px"
          >
          <v-card>
          <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense>
            {{message}}
          </v-alert>
          <v-card-title>
          <span class="text-h5">设置密码</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form
              ref="formPwd"
              v-model="valid"
              lazy-validation
              >
                <v-row justify="center" align-content="center">
                  <!-- <v-col
                    cols="12"
                    sm="6"
                    md="9"
                    >
                    <v-text-field v-model="mobile" class="mx-auto col-11" label="手机号" :rules="mobileRules" require />
                  </v-col> -->
                  <v-col
                  cols="12"
                  sm="6"
                  md="9"
                  >
                    <v-text-field
                            v-model="password"
                            class="my-4 mx-auto col-11"
                            :type="'password'"
                            :rules="[v => !!v || '密码不能为空']"
                            label="密码"
                            require
                        />
                    </v-col>
                    <v-col
                    cols="12"
                    sm="6"
                    md="9"
                    >
                      <v-text-field
                          v-model="confirm"
                          class="my-4 mx-auto col-11"
                          :type="'password'"
                          :rules="[v => !!v || '确认密码不能为空']"
                          label="确认密码"
                          require
                      />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="grey darken-1"
          text
          @click="confirmDialog = false;reset()"
          >
          关闭
          </v-btn>
          <v-btn
          color="orange darken-1"
          text
          @click="validatePwdData"
          >
          确定
          </v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>
     </v-row>
  </v-container>
  
</template>

<script>
import {loginOut,getChangeCode ,vailConfirm,memberChange} from '@/api/user.js'
import {mapState} from 'vuex'

export default {
  data: () => ({
      valid:true,
      confirmDialog:false,
      changeDialog:false,
      validates:'',
      verifyhash:'',
      messageError:false,
      message:'',
      dynamic:'获取验证码',
      codeShow:true,
      mobileRules:[ v => /^[1][3456789][0-9]{9}$/.test(v) || '请填写正确的手机号码'],
      mobile:'',
      password:'',
      confirm:'',
      count:60,
  }),
  computed:{
    ...mapState({
      sub:state => state.user.sub,
    })
  },
  methods: {
    mobileRule(value){
        if(value ===null){
            return false
        }
        if(value.length === 0){
            return "手机号不能为空";
        }
        else if(value.length > 0){
            if(value.length===11){
            var Reg = /^[1][3456789][0-9]{9}$/;
            if(!Reg.test(value)){
                return "请正确填写手机号";
            }else{
                return true
            }
            }
            else if(value.length>11){
                return "请正确填写手机号";
            }else{
            return false
            }
        }
    },
    Logout() {
      loginOut(window.sessionStorage.getItem('token')).then(res=>{
        //  console.log(res);
         window.sessionStorage.clear();  
         this.$router.push('/login');

       })
    },
    account(){
      //  this.$router.push('/account')
      this.changeDialog = true
    },
    // 获取动态验证码
    getDynamic(){
        var Reg = /^[1][3456789][0-9]{9}$/;
        if(Reg.test(this.mobile)){
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                var params={
                    phonenumber:this.mobile
                  }
                  getChangeCode(params).then(res=>{
                    if(res.data.code===200){
                      
                    }else{
                        this.$nextTick(()=>{
                          this.message = res.data.message;
                          this.messageError = true;
                          setTimeout(()=>{
                              this.messageError = false;
                          },2000)
                        })
                    }
                  })
                this.codeShow = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                    } else {
                    this.codeShow = true;
                    clearInterval(this.timer);
                    this.timer = null;
                    }
                }, 1000)
            }
        }else{
           this.$nextTick(()=>{
            this.message = "请先填写手机号";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
        }
    },
    validateMoblie(){
      if (!this.mobile || !this.validates) {
         this.$refs.form.validate()
        this.$nextTick(()=>{
            this.message = "手机号与验证码不能为空";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
        return false;
      }
      var params={
        phonenumber:this.mobile,
        verifycode:this.validates
      }
      vailConfirm(params).then(res=>{
        // console.log(res);
        if(res.data.code===200){
                this.verifyhash = res.data.data.verifyhash
                this.changeDialog = false;
                this.confirmDialog = true;

        }else{
            this.$nextTick(()=>{
              this.message = res.data.message;
              this.messageError = true;
              setTimeout(()=>{
                  this.messageError = false;
              },2000)
            })
        }
      })
    },
    validatePwdData(){
      if(!this.password||!this.confirm){
         this.$refs.formPwd.validate()
         this.$nextTick(()=>{
            this.message = "请确认是否填写";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
         return false
       }
      var params = {
        verifyhash:this.verifyhash,
        password:this.password,
        confirm:this.confirm
      }
      memberChange(params).then(res=>{
        if(res.data.code===200){
            this.confirmDialog = false;
            loginOut(window.sessionStorage.getItem('token')).then(res=>{
              // console.log(res);
              window.sessionStorage.clear();  
              this.$router.push('/login');
            })
        }else{
              this.$nextTick(()=>{
                this.message = res.data.message;
                this.messageError = true;
                setTimeout(()=>{
                    this.messageError = false;
                },2000)
            })
        }
      })
    },
    reset(){
      this.mobile='';
      this.validates='';
      this.password = '';
      this.confirm = ''
      this.$refs.form.reset();
    },
  }
}
</script>

<style scoped>
.header{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header:hover{
  cursor: pointer;
}
.message{
  position: fixed;
  opacity: 0;
  top:0px;
  right: 50%;
  transform: translateX(50%);
  padding-right: 50px;
  animation-name: example;
  animation-duration: 6s;
}
@keyframes example {
  0%   {  opacity: 1; right: 50%;transform: translateX(50%); top:0px;}
  10%  { opacity: 1; right: 50%;; top:50px;}
  100% {opacity: 0; right: 50%;; top:50px;}
}
</style>
